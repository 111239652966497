import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Octagon } from '../assets/octagon.svg';
import { ReactComponent as Back } from '../assets/back-button.svg';
import './nosubs.css';
import MailTo from '../components/ButtonMailto';

export default function NoSubs() {
  let navigate = useNavigate();
  return (
    <div className="app-container" style={{ border: 'none' }}>
      <div className="noSubsContent">
        <Back
          style={{
            position: 'absolute',
            top: '0',
            alignSelf: 'start',
            cursor: 'pointer',
          }}
          onClick={() => navigate(-1)}
        />
        <Octagon />
        <div className="noSubsHeading">No active subscription found</div>
        <div className="noSubsText">
          If you believe this is a mistake please contact the help desk
        </div>
        <MailTo weight="bold" size="13" />
      </div>
    </div>
  );
}
