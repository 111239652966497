import { buildRequestOptions, handleResponse } from '../../utils';
import {
  portalToolkitSKU,
  schemaReplicatorSKU,
  hsmSchProductCodes,
} from '../../utils/productSKU';

const migrationStatusMapping = {
  start: 'Running',
  stop: 'Stopped',
  restart: 'Running',
};

export const updateOrderStatus = (orderId, newStatus) => ({
  type: 'UPDATE_ORDER_STATUS',
  payload: { orderId, newStatus: migrationStatusMapping[newStatus] },
});

export const fetchHSMOrderData = (sku) => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_HSM_DATA_START' });

  try {
    const skus = sku === 'HSM' ? ['HSMLITE', 'HSMPRO', 'HSMENT'] : [sku];
    const requestOptions = buildRequestOptions('POST', { skus });

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/migrations/search`,
      requestOptions
    );

    let hsmData = await handleResponse(
      response,
      dispatch,
      'FETCH_HSM_DATA_SUCCESS',
      'FETCH_HSM_DATA_FAILURE'
    );

    // if the response is not empty we need to put the response inside orders key
    if (hsmData && !hsmData?.orders) {
      hsmData = { orders: hsmData };
    }

    // filter orders that have productCode : null or undefined
    if (hsmData?.orders) {
      hsmData.orders = hsmData.orders.filter((order) => order.productCode);
    }

    if (hsmData) {
      const existingData = getState().app.hsmOrders || { orders: [] };

      let payload;
      if (hsmData?.orders) {
        console.log('existingData', existingData);
        console.log('hsmData.orders', hsmData.orders);

        if (Array.isArray(hsmData.orders)) {
          if (existingData?.orders?.length > 0) {
            payload = {
              ...hsmData,
              orders: [...existingData.orders, ...hsmData.orders],
            };
          } else {
            payload = { ...hsmData, orders: [...hsmData.orders] };
          }
        } else if (
          typeof hsmData.orders === 'object' &&
          hsmData.orders !== null
        ) {
          payload = {
            ...hsmData,
            orders: { ...existingData.orders, ...hsmData.orders },
          };
        }
      } else {
        payload = { ...hsmData, orders: { ...existingData.orders } };
      }

      // FETCH_HSM_DATA_NOT_FOUND
      if (
        response.status === 204 ||
        !hsmData?.orders ||
        hsmData?.orders.length === 0
      ) {
        dispatch({ type: 'ADD_FETCHED_SKU', payload: sku });
        dispatch({ type: 'FETCH_HSM_DATA_NOT_FOUND', payload: sku });
        return;
      }

      dispatch({
        type: 'FETCH_HSM_DATA_SUCCESS',
        payload,
      });
    }
    dispatch({ type: 'ADD_FETCHED_SKU', payload: sku });
  } catch (error) {
    console.error('Error:', error);
  }
};

export const fetchOrderDataById = (id) => async (dispatch) => {
  dispatch({ type: 'FETCH_HSM_DATA_START' });

  try {
    const requestOptions = buildRequestOptions('GET');
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/migrations/${id}`,
      requestOptions
    );
    let responseData = await handleResponse(
      response,
      dispatch,
      'FETCH_HSM_DATA_SUCCESS',
      'FETCH_HSM_DATA_FAILURE'
    );

    // if the response is not empty we need to put the response inside orders key
    if (responseData && !responseData.orders) {
      responseData = { orders: [responseData] };
    }

    // filter orders that have productCode : null or undefined
    if (responseData?.orders) {
      responseData.orders = responseData.orders.filter(
        (order) => order.productCode
      );
    }

    if (responseData) {
      if (responseData.orders && Array.isArray(responseData.orders)) {
        dispatch({
          type: 'FETCH_HSM_DATA_SUCCESS',
          payload: { orders: responseData.orders },
        });
      } else {
        dispatch({
          type: 'FETCH_HSM_DATA_FAILURE',
          error: 'Unexpected data structure',
        });
        console.error('Unexpected data structure:', responseData);
      }
    }
  } catch (error) {
    console.error('Error:', error);
    dispatch({ type: 'FETCH_HSM_DATA_FAILURE', error });
  }
};

export const fetchOperations = (sku) => async (dispatch) => {
  let dispatchType;
  let skuToFetch;
  let productCodeToFetch;
  let productName;
  switch (sku) {
    case portalToolkitSKU:
      dispatchType = 'PORTAL_TOOLKIT';
      skuToFetch = [portalToolkitSKU];
      productCodeToFetch = [portalToolkitSKU];
      productName = 'Portal Toolkit';
      break;
    case schemaReplicatorSKU:
      dispatchType = 'SCHEMA_REPLICATOR';
      skuToFetch = ['HSHSSCHMIGL', 'HSHSSCHMIGP'];
      productCodeToFetch = hsmSchProductCodes;
      productName = 'Schema Replicator';
      break;
    default:
      break;
  }

  dispatch({ type: `FETCH_${dispatchType}_START` });
  try {
    const requestOptions = buildRequestOptions('POST', {
      skus: [...skuToFetch],
    });

    // First request: Check subscription
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/hsm/users/subscriptions`,
      requestOptions
    );

    if (response.status === 204 || response.status === 404) {
      dispatch(noSubscriptionFound(sku));
      return;
    }

    if (!response.ok) {
      throw new Error(sku);
    }

    const data = await response.json();
    if (!data || data.length === 0) {
      dispatch(noSubscriptionFound(sku));
      return;
    }

    const hsmSubscription = data
      .filter(
        (subscription) =>
          productCodeToFetch.includes(subscription.productCode) &&
          subscription.status === 'Active' &&
          subscription.orderDate
      )
      .sort((a, b) => {
        const dateA = new Date(a.orderDate);
        const dateB = new Date(b.orderDate);
        if (isNaN(dateA) || isNaN(dateB)) {
          return 0;
        }
        return dateB - dateA;
      });

    const result = hsmSubscription.length > 0 ? hsmSubscription[0] : null;

    if (!result) {
      // Treat as if the call didn't respond well
      // throw new Error(`${portalToolkitSKU}`);
      dispatch(noSubscriptionFound(sku));
      return;
    }

    const subscriptionId = hsmSubscription[0].id;

    // Third request: Get operations
    const operationsResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/subscriptions/${subscriptionId}/migrations`,
      buildRequestOptions('GET')
    );

    let operationsData = [];
    if (operationsResponse.status === 204) {
      // No content, but this is not an error. Just an empty array of .
      console.log('No operations found');
    } else if (operationsResponse.ok) {
      operationsData = await operationsResponse.json();
      console.log('operationsData', operationsData);
      // filter orders that have productCode different than the one we are looking for
      if (operationsData) {
        operationsData = operationsData.filter((order) =>
          productCodeToFetch.includes(order.productCode)
        );
      }
    } else {
      throw new Error(sku);
    }

    // Dispatch success with combined data
    dispatch({
      type: `FETCH_${dispatchType}_SUCCESS`,
      payload: {
        subscription: hsmSubscription[0],
        operations: operationsData,
      },
    });
  } catch (error) {
    console.error('Error:', error);
    dispatch({
      type: `FETCH_${dispatchType}_FAILURE`,
      payload:
        error.message || `An error occurred while fetching ${productName} data`,
    });
  }
};

export const fetchSpinoffData = (subscriptionId) => async (dispatch) => {
  dispatch({ type: 'FETCH_SPINOFF_START' });
  try {
    const spinoffResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/subscriptions/${subscriptionId}/spinoff`,
      buildRequestOptions('POST')
    );
    if (!spinoffResponse.ok) {
      throw new Error(`HTTP error! status: ${spinoffResponse.status}`);
    }
    const spinoffData = await spinoffResponse.json();

    dispatch({
      type: 'FETCH_SPINOFF_SUCCESS',
      payload: spinoffData,
    });

    return spinoffData;
  } catch (error) {
    console.error('Error:', error);
    dispatch({
      type: 'FETCH_SPINOFF_FAILURE',
      payload: error.message || 'An error occurred while fetching spinoff data',
    });
  }
};

export const noSubscriptionFound = (sku) => ({
  type: `NO_${sku}_SUBSCRIPTION_FOUND`,
  payload: `No subscription found for ${sku}`,
});
