import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './detail.css';
import { productMap } from '../utils/productSKU';
import Status from '../components/Status';
import useOutsideClick from './useOutsideClick';
import { Timeout } from '../utils/timeout';
import {optionTime} from '../utils/optionTime';
import Spinner from '../utils/spinner';
import { DBTYPES } from '../utils/dbTypes';
import MessageModal from './Modal';
import { useSelector } from 'react-redux';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateSubscription, updateSubscriptionDatabaseInfo, fetchSubscriptionsBySubscriptionId } from '../store/actions/subscriptionActions';
import { ErrorMessage, SuccessMessage, SyncTimeForm, handleClose } from './SyncTime/SyncTime';


const { GOOGLE_BQ } = DBTYPES;

function Detail() {
  const [reauthModalOpen, setReauthModalOpen] = useState(false);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [sent, setSent] = useState(false);
  const [state, setState] = useState();
  const [active, setActive] = useState(false);
  const [resetMenu, setResetMenu] = useState(false);
  const [updateOrderMenu, setupdateOrderMenu] = useState(false);
  const [updateFinished, setupdateFinished] = useState(false);
  const [updateDBFinished, setupdateDBFinished] = useState(false);
  const [updateDBMenu, setupdateDBMenu] = useState(false);
  const [testDBConnection, setTestDBConnection] = useState(false);
  const [updateDBError, setupdateDBError] = useState(false);
  const [changeSyncTime, setChangeSyncTime] = useState(false);
  const [testDBError, setTestDBError] = useState(false);
  const [testDBErrorMessage, setTestDBErrorMessage] = useState('');
  const [testDBFinished, setTestDBFinished] = useState(false);
  const [dbTestAndUpdateErrorMessage, setDBTestAndUpdateErrorMessage] =
    useState('');
  const [updateOrderError, setupdateOrderError] = useState(false);
  const [submitting, setsubmitting] = useState(false);
  const [submittingDB, setsubmittingDB] = useState(false);
  const [changingSyncTime, setChangingSyncTime] = useState(false);
  const [successChangeSyncTime, setSuccessChangeSyncTime] = useState(false);
  const [errorChangeSyncTime, setErrorChangeSyncTime] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const allSubscriptions = useSelector((state) => state.subscription.subscriptions);
  const hasInitialized = useSelector((state) => state.subscription.hasInitialized);
  const [time, setTime] = useState('');
  const [updatePassword, setUpdatePassword] = useState(false);
  const [updatePasswordMessage, setUpdatePasswordMessage] = useState('');
  const [loadingPasswordReset, setLoadingPasswordReset] = useState(false);

  const dispatch = useDispatch();

  const clickSend = () => {
    setMenu(!menu);
  };

  const resetPassword = () => {
    setResetMenu((state) => !state);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ subscriptionID: state.id }),
    };
    setLoadingPasswordReset(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/api/users/resetpassword`,
      requestOptions
    ).then(() => {
      setLoadingPasswordReset(false);
      setUpdatePasswordMessage('Password reset email sent');
      setUpdatePassword(true);
    }).catch(() => {
      setLoadingPasswordReset(false);
      setUpdatePasswordMessage('Failed to send password reset email');
      setUpdatePassword(true);
    });
  };

  const cmdSend = () => {
    setMenu(!menu);
    setSent(true);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ subscriptionID: state.id }),
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/api/users/sendemail`,
      requestOptions
    ).then((res) => console.log('response', res.status));
  };
  const updateInfo = async (e) => {
    setsubmitting(true);
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ subscriptionID: state.id, ...formJson }),
      signal: Timeout(30).signal,
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/api/users/updateinfo`,
      requestOptions
    )
      .then((res) => {
        console.log('response', res.status);
        if (![200, 202].includes(res.status)) {
          setupdateOrderError(true);
        } else {
          const id = state?.id;
          const email = formJson["email"];
          const firstName = formJson["firstName"];
          const lastName = formJson["lastName"];
          const company = formJson["company"];
      
          dispatch(updateSubscription(id, email, firstName, lastName, company));  
        }
        setupdateOrderError(false);
        setupdateFinished(true);
      })
      .catch((err) => {
        console.log(err.toString());
        setupdateOrderError(true);
        setupdateFinished(true);
      });
  };

  const updateSyncTime = async (e) => {
    e.preventDefault();
    setChangingSyncTime(true);

    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ time: formJson.time }),
      signal: Timeout(30).signal,
    };

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/subscriptions/${state.id}/customizations`,
        requestOptions
      );

      if (!res.ok) {
        throw new Error(`Request failed with status: ${res.status}`);
      }
      setTime(formJson.time);
      setSuccessChangeSyncTime(true);
    } catch (err) {
      console.error(err);
      setErrorChangeSyncTime(true);
    } finally {
      setChangingSyncTime(false);
    }
  };
  
  const updateDB = async (e) => {
    setsubmittingDB(true);
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    let requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ subscriptionID: state.id, ...formJson }),
      signal: Timeout(30).signal,
    };
    if (isBYOD) {
      requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          subscriptionID: state.id,
          ...formJson,
          testCredentials: true,
        }),
        signal: Timeout(30).signal,
      };
      if (isGoogleBQ) {
        requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            subscriptionID: state.id,
            targetDatabaseConfig: { ...formJson },
            testCredentials: true,
          }),
          signal: Timeout(30).signal,
        };
      }
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/api/users/updatedbcredentials`,
          requestOptions
        );

        const result = await res.json();

        if (![200, 202].includes(res.status) || !result.success) {
          setupdateDBError(true);
          setDBTestAndUpdateErrorMessage(result.error);
        } else {
          setupdateDBError(false);
          const id = state.id;
          const dbName = formJson["dbName"];

          dispatch(updateSubscriptionDatabaseInfo(id, dbName));
        }

        setupdateDBFinished(true);

      } catch (err) {
        setDBTestAndUpdateErrorMessage(err.toString());
        setupdateDBError(true);
        setupdateDBFinished(true);
      }
    } else {
      requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ subscriptionID: state.id, ...formJson }),
        signal: Timeout(30).signal,
      };
      await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/updatedb`,
        requestOptions
      )
        .then((res) => {
          console.log('response', res.status);
          if (![200, 202].includes(res.status)) {
            setupdateDBError(true);
          } else {
            res.json().then((result) => {
              const id = state.id;
              const dbName = result.dbName;
              dispatch(updateSubscriptionDatabaseInfo(id, dbName));
              setupdateDBError(false);
            }).catch((jsonError) => {
              console.error('JSON parsing error:', jsonError);
              setupdateDBError(true);
            });
          }
          setupdateDBFinished(true);
        })
        .catch((err) => {
          console.log(err.toString());
          setupdateDBError(true);
          setupdateDBFinished(true);
        });
    }
  };

  const testDatabaseConnection = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ subscriptionID: state.id, testCredentials: true }),
      signal: Timeout(30).signal,
    };
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/updatedbcredentials`,
        requestOptions
      );
      const response = await res.json();
      console.log('response', res.status);
      if (![200, 202].includes(res.status) || !response.success) {
        setTestDBErrorMessage(response.error);
        setTestDBError(true);
      } else {
        setTestDBError(false);
      }
      setTestDBFinished(true);
    } catch (err) {
      console.log(err);
      if (err.toString() === 'TypeError: Failed to fetch') {
        setTestDBErrorMessage(
          'A network/firewall issue is preventing connection. Please contact support for more details.'
        );
        setTestDBError(true);
        setTestDBFinished(true);
      } else {
        setTestDBErrorMessage(err.toString());
        setTestDBError(true);
        setTestDBFinished(true);
      }
    }
  };

  useEffect(() => {
    try {
      let sub = allSubscriptions.find((item) => item.id === id);
      if (sub) {
        if (sub.dbType !== GOOGLE_BQ) {
          setState(sub);
        } else {
          const requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
          };
          fetch(
            `${process.env.REACT_APP_API_URL}/api/users/subscriptions/${sub.id}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) => {
              setState(json);
              if (JSON.parse(searchParams.get('redirect_from_auth'))) {
                setupdateDBMenu(true);
              }
            })
            .catch((err) => {
              console.log(err);
              navigate(`/account/my-app-information-item/${sub.productCode}`);
            });
        }
        if (sub.status === 'active') {
          setActive(true);
        }
      }
    } catch (error) {
      console.log(error);
      navigate('/account/my-app-information-item');
    }
    
  }, [id, allSubscriptions, navigate, searchParams]);

  useEffect(() => {
    if (testDBConnection) {
      testDatabaseConnection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testDBConnection]);
  
  useEffect(() => {
    if (!hasInitialized) {
      dispatch(fetchSubscriptionsBySubscriptionId(id));  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInitialized]);

  useEffect(() => {
    // check if redirect_from_reauth=true is in the search params
    if (JSON.parse(searchParams.get('redirect_from_reauth'))) {
      setReauthModalOpen(true);
    }
  }, [searchParams]);

  const clickCheck = () => {};
  const updateRef = useOutsideClick(clickCheck);
  const dbRef = useOutsideClick(clickCheck);
  const credsRef = useOutsideClick(clickCheck);
  const resetRef = useOutsideClick(clickCheck);
  const resultRef = useOutsideClick(clickCheck);
  const passwordRef = useOutsideClick(clickCheck);

  function handleReconnectToHubSpot() {
    const redirectUrl = `${window.location.origin}/account/my-app-information-item/subscription/${state.id}?redirect_from_reauth=true`;
    console.log('redirectUrl', redirectUrl);

    fetch(
      `${process.env.REACT_APP_API_URL}/api/oauth/getlink/${state.id}?redirectUrl=${redirectUrl}`,
      {
        method: 'GET',
        credentials: 'include',
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Failed to reconnect to HubSpot');
      })
      .then((data) => {
        window.location.href = data.redirectUrl;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const isBYOD = state?.productCode === 'HSBYOD';
  const isGoogleBQ = state?.dbType === GOOGLE_BQ;

  const formatSyncTime = (minutes) => {
    if (!minutes) return 'Invalid sync time';
    if (!Number.isInteger(minutes)) return 'Invalid sync time';

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours === 0) {
      return `${remainingMinutes} minutes`;
    } else if (remainingMinutes === 0) {
      return `${hours} hours`;
    } else {
      return `${hours} hours ${remainingMinutes} minutes`;
    }
  };

  return state ? (
    <>
    <ToastContainer />
    <div className="app-container">
      <div className="detailintro">
        <div className="gridheader1">
          {
            productMap.find(
              (item) =>
                item.sku === state.productSKU || item.sku === state.productCode
            )?.name
          }
        </div>
        <div
          className="gridheader2"
          style={{
            fontWeight: 'var(--fw-bold)',
            cursor: 'pointer',
            color: '#E8662E',
          }}
          onClick={() => setModalOpen(true)}
        >
          {state.lastSyncDate &&
            moment(state.lastSyncDate)
              .tz('America/New_York')
              .format('MM/DD/YY')}
        </div>
        <MessageModal
          isOpen={reauthModalOpen} // Use the corresponding modal state
          setIsOpen={() => setReauthModalOpen(true)}
          setIsClose={() => setReauthModalOpen(false)}
          title="Re-Authentication Complete"
          message={[]}
        />
        {loadingPasswordReset && (
          <div className="date-dialog" ref={passwordRef}>
            <div className="pos-center-dialog">
              <Spinner />
            </div>
          </div>
        )}
        <MessageModal
          passwordRef={passwordRef}
          isOpen={updatePassword} // Use the corresponding modal state
          setIsOpen={() => setUpdatePassword(true)}
          setIsClose={() => setUpdatePassword(false)}
          title={updatePasswordMessage}
        />

        <MessageModal
          isOpen={modalOpen} // Use the corresponding modal state
          setIsOpen={() => setModalOpen(true)}
          setIsClose={() => setModalOpen(false)}
          title="Last Sync Time"
          message={[
            {
              title: state.productSKU,
              value: moment(state.lastSyncDate)
                .tz('America/New_York')
                .format('MM/DD/YY hh:mm:ss A'),
            },
          ]}
        />
        <div className="gridheader3">
          <Status status={state.status} />
        </div>
        <div
          className="gridheader4"
          style={{
            fontSize: '12px',
            color: 'var(--clr-purple)',
            fontWeight: 'var(--fw-bold)',
            textDecoration: 'underline',
            textAlign: 'end',
            cursor: 'pointer',
          }}
          onClick={() =>
            navigate(
              `/account/my-app-information-item/${
                state.productSKU || state.productCode
              }`
            )
          }
        >
          Back to Subscriptions
        </div>
      </div>
      <div className="detailgrid">
        <div className="detailheading gridpurchase">PURCHASE INFO</div>
        <div id="orderinfo" className="detailheading gridorder">
          ORDER INFO
        </div>
        <div id="datainfo" className="detailheading griddatabase">
          {isBYOD ? 'CONNECTION' : 'DATABASE'} INFO
        </div>
        <div id="password" className="detailheading gridpassword">
          ACTION
        </div>
        <div className="row-border gridline"></div>
        <div className="row-border gridline2"></div>
        <div className="row-border gridline3"></div>
        <div className="row-border gridline4"></div>
        <div className="detailheading gridpurchase2">
          <div className="detailfield">
            <div>Sync frequency</div>
            <div style={{ fontWeight: 'var(--fw-bold)' }}>
              {formatSyncTime(state.syncIntervalMin)}
            </div>
          </div>
        </div>
        <div className="detailheading gridorder2">
          <div className="detailfield">
            <div>Email</div>
            <div
              title={state.email}
              style={{
                fontWeight: 'var(--fw-bold)',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {state.email}
            </div>
          </div>
        </div>
        <div className={`detailheading  ${isBYOD ? "griddatabase3" : "griddatabase2"}`}>
          <div className="detailfield">
            <div>{isGoogleBQ ? 'Project Name' : 'Database'}</div>
            <div style={{ fontWeight: 'var(--fw-bold)' }}>
              {isGoogleBQ
                ? state.targetDatabaseConfig?.projects?.[
                    state.targetDatabaseConfig?.projectId
                  ]
                : state.dbName}
            </div>
          </div>
        </div>
        <div
          className="btnContainer detailheading gridpassword2"
          style={{ justifySelf: 'end' }}
        >
          {!isBYOD && (
            <div id="passDiv" onClick={active && !sent ? clickSend : undefined}>
              {sent ? (
                `Email sent to ${state.email}`
              ) : (
                <div className={active ? 'emailbtn' : 'emailbtn is-disabled '}>
                  SEND CREDENTIALS
                </div>
              )}
            </div>
          )}
          {!isBYOD && (
            <div
              className={`resetBtn emailbtn ${
                state.status === 'inactive' ? 'is-disabled' : ''
              }`}
              onClick={() => setResetMenu((state) => !state)}
            >
              RESET PASSWORD
            </div>
          )}
          <div
            className={`resetBtn emailbtn ${
              state.status === 'inactive' ? 'is-disabled' : ''
            }`}
            onClick={() => setupdateOrderMenu((state) => !state)}
          >
            UPDATE ORDER INFO
          </div>
          <div
            className={`resetBtn emailbtn ${
              state.status === 'inactive' ? 'is-disabled' : ''
            }`}
            onClick={() => setupdateDBMenu((state) => !state)}
          >
            CHANGE {isBYOD ? 'CONNECTION' : 'DATABASE'} INFO
          </div>
          {isBYOD && (
            <div
              className={`resetBtn emailbtn ${
                state.status === 'inactive' ? 'is-disabled' : ''
              }`}
              onClick={() => setTestDBConnection((state) => !state)}
            >
              CONNECTION STATUS
            </div>
          )}
          {(isBYOD || state.isProductServedByHSC) && (
            <>
              <div
                className={`resetBtn emailbtn change-sync-time ${
                  state.status === 'inactive' ? 'disabled-grey'
                  : state.syncIntervalMin === 1440 ? 'disabled-grey popover' : ''
                }`}
                onClick={() => state.status !== 'inactive' && state.syncIntervalMin !== 1440 && setChangeSyncTime((state) => !state)}
              >
                SELECT SYNC TIME
              </div>
              <div
                className={`resetBtn emailbtn ${
                  state.status === 'inactive' ? 'disabled-grey' : ''
                }`}
                onClick={() => state.status !== 'inactive' && handleReconnectToHubSpot()}
              >
                RECONNECT TO HubSpot
              </div>
            </>
          )}
        </div>
        <div className="detailheading gridpurchase3">
          <div className="detailfield">
            <div>Company</div>
            <div style={{ fontWeight: 'var(--fw-bold)' }}>{state.company}</div>
          </div>
        </div>
        <div className="detailheading gridorder3">
          <div className="detailfield">
            <div>First Name</div>
            <div style={{ fontWeight: 'var(--fw-bold)' }}>
              {state.firstName}
            </div>
          </div>
        </div>
        <div className={`detailheading  ${isBYOD ? "griddatabase4" : "griddatabase3"}`} id="sname">
          <div className="detailfield">
            <div>{isGoogleBQ ? 'Dataset' : 'Server'} Name</div>
            <div
              style={{
                fontWeight: 'var(--fw-bold)',
                overflowWrap: 'anywhere',
              }}
            >
              {!active
                ? ''
                : isGoogleBQ
                ? state.targetDatabaseConfig?.datasetName
                : state.dbServerName}
            </div>
          </div>
        </div>
        {isBYOD && (
          <div className="detailheading  griddatabase2">
            <div className="detailfield">
              <div>Database type</div>
              <div
                style={{
                  fontWeight: 'var(--fw-bold)',
                  overflowWrap: 'anywhere',
                }}
              >
                {state.dbType}
              </div>
            </div>
          </div>
        )}

        <div className="detailheading gridpurchase4">
          <div className="detailfield">
            <div>HubSpot Portal ID</div>
            <div style={{ fontWeight: 'var(--fw-bold)' }}>{state.portalId}</div>
          </div>
        </div>
        <div className="detailheading gridorder4">
          <div className="detailfield">
            <div>Last Name</div>
            <div style={{ fontWeight: 'var(--fw-bold)' }}>{state.lastName}</div>
          </div>
        </div>
        <div className="detailheading  griddatabase4" id="sync">
          <div className="detailfield"></div>
        </div>
      </div>
      {resetMenu && (
        <div className="dialog" ref={resetRef}>
          <div className="dialogtext">
            Are you sure you want to reset the database password?
          </div>

          <div className="dialogitem" onClick={resetPassword}>
            Yes
          </div>
          <div
            className="dialogitem"
            onClick={() => setResetMenu((state) => !state)}
          >
            No
          </div>
        </div>
      )}
      {menu && (
        <div className="dialog" ref={credsRef}>
          <div className="dialogtext">
            Are you sure you want to send database credentials to email address{' '}
            <span style={{ fontWeight: 'var(--fw-bold)', fontStyle: 'normal' }}>
              {state.email}
            </span>
            ?
          </div>

          <div className="dialogitem" onClick={cmdSend}>
            Yes
          </div>
          <div className="dialogitem" onClick={clickSend}>
            No
          </div>
        </div>
      )}
      {updateOrderMenu && (
        <div className="dialog">
          <div
            style={{
              fontSize: '14px',
              fontWeight: '700',
              gridColumn: 'span 2',
              color: 'rgb(105, 114, 125)',
            }}
          >
            Update Order Info
          </div>
          {updateFinished ? (
            <div ref={resultRef}>
              {updateOrderError ? (
                <div className="dialogtext" style={{ marginBottom: '1em' }}>
                  <p>Something went wrong.</p>
                </div>
              ) : (
                <div className="dialogtext" style={{ marginBottom: '1em' }}>
                  Your order info will be updated momentarily. The My App
                  Information panel may take a few minutes to update. You will
                  need to reload the My App Information section to see your
                  changes.
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  gridColumn: 'span 2',
                }}
              >
                <div
                  className="dialogitem"
                  onClick={() => {
                    setupdateOrderMenu((state) => !state);
                    setupdateFinished(false);
                    setsubmitting(false);
                    setupdateOrderError(false);
                  }}
                >
                  Close
                </div>
              </div>
            </div>
          ) : (
            <form
              style={{ lineHeight: '50px', gridColumn: 'span 2' }}
              method="post"
              ref={updateRef}
              onSubmit={updateInfo}
            >
              <input
                type="text"
                name="firstName"
                className="dwinputbox"
                placeholder="First name"
                defaultValue={state.firstName}
              />
              <input
                type="text"
                name="lastName"
                className="dwinputbox"
                placeholder="Last name"
                defaultValue={state.lastName}
              />
              <input
                type="text"
                name="company"
                className="dwinputbox"
                placeholder="Company"
                defaultValue={state.company}
              />
              <input
                type="text"
                name="email"
                className="dwinputbox"
                placeholder="Email"
                defaultValue={state.email}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginTop: '1em',
                }}
              >
                <button
                  type="submit"
                  disabled={submitting}
                  className="dwbutton  dialogitem"
                  style={{
                    backgroundColor: submitting && 'var(--clr-purplefaded)',
                  }}
                >
                  UPDATE
                </button>
                <button
                  disabled={submitting}
                  className="dwbutton dialogitem"
                  onClick={() => setupdateOrderMenu((state) => !state)}
                  style={{
                    backgroundColor: submitting && 'var(--clr-purplefaded)',
                  }}
                >
                  CANCEL
                </button>
              </div>
            </form>
          )}
        </div>
      )}
      {updateDBMenu && (
        <div className={`dialog ${isGoogleBQ ? 'gbq' : ''}`}>
          <div
            style={{
              fontSize: '14px',
              fontWeight: '700',
              textAlign: 'center',
              gridColumn: 'span 2',
              color: 'rgb(105, 114, 125)',
            }}
          >
            Change {isBYOD ? 'Connection' : 'Database'} Info
          </div>
          {updateDBFinished ? (
            <div ref={resultRef} style={{ gridColumn: '1/-1', width: '100%' }}>
              {updateDBError ? (
                <div
                  className="dialogtext"
                  style={{ marginBottom: '1em', textAlign: 'center' }}
                >
                  <p className="error">{dbTestAndUpdateErrorMessage}</p>
                </div>
              ) : (
                <>
                  {!isBYOD ? (
                    <div className="dialogtext" style={{ marginBottom: '1em' }}>
                      <p>
                        An email with your updated information will be sent to
                        the email address associated with this subscription
                        within 5 minutes.
                      </p>
                      <br></br>
                      <p>
                        The My App Information panel will not update until
                        unless you reload the panel.
                      </p>
                    </div>
                  ) : (
                    <div className="dialogtext" style={{ marginBottom: '1em' }}>
                      <p>
                        Your Connection Info will be updated momentarily. The My
                        App Information panel may take a few minutes to update.
                        You will need to reload the My App Information section
                        to see your changes.
                      </p>
                    </div>
                  )}
                </>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  gridColumn: 'span 2',
                }}
              >
                <div
                  className="dialogitem"
                  onClick={() => {
                    setupdateDBMenu((state) => !state);
                    setupdateDBFinished(false);
                    setsubmittingDB(false);
                    setupdateDBError(false);
                  }}
                >
                  Close
                </div>
              </div>
            </div>
          ) : (
            <>
              {!isBYOD ? (
                <div className="dialogtext">
                  <p>
                    A number series will be appended to your choices
                    automatically for security reasons.
                  </p>
                  <p>If you leave a field blank no change will be made.</p>
                </div>
              ) : (
                <div className="dialogtext text-center">
                  <p>
                    Please click the Close button when you are done editing your
                    connection details.
                  </p>
                  {/* <p>If you leave a field blank no change will be made.</p> */}
                </div>
              )}

              <form
                style={{ lineHeight: '50px', gridColumn: 'span 2' }}
                method="post"
                onSubmit={updateDB}
                ref={dbRef}
              >
                {isBYOD && !isGoogleBQ && (
                  <input
                    type="text"
                    name="dbServerName"
                    className="dwinputbox"
                    placeholder="Server Address"
                  />
                )}
                {!isGoogleBQ && (
                  <input
                    type="text"
                    name="dbName"
                    className="dwinputbox"
                    placeholder="Database name"
                  />
                )}
                {!isBYOD ? (
                  <input
                    type="text"
                    name="dbUserName"
                    className="dwinputbox"
                    placeholder="Database user name"
                  />
                ) : (
                  <>
                    {!isGoogleBQ ? (
                      <>
                        <input
                          type="text"
                          name="dbUserName"
                          pattern="^[^@]+$"
                          className="dwinputbox"
                          placeholder="Database user"
                        />
                        <input
                          type="password"
                          name="dbPassword"
                          className="dwinputbox"
                          placeholder="Database user password"
                        />
                      </>
                    ) : (
                      <>
                        <label className="grid-columns-2">
                          Google authorization
                          <a
                            href={state.targetDatabaseConfig?.authorizeUrl}
                            className={`btn btn-primary ${
                              !state.targetDatabaseConfig?.authorizeUrl
                                ? 'is-disabled'
                                : ''
                            }`}
                          >
                            Reauthorize
                          </a>
                        </label>
                        <label className="grid-columns-2">
                          Google account
                          <input
                            type="text"
                            name="accountName"
                            className="dwinputbox border-grey"
                            defaultValue={
                              state.targetDatabaseConfig?.accountName || ''
                            }
                            readOnly
                          />
                        </label>
                        <label className="grid-columns-2">
                          Project
                          {Object.keys(
                            state.targetDatabaseConfig?.projects || {}
                          ).length > 0 ? (
                            <select
                              name="projectId"
                              className="dwinputbox border-grey"
                              defaultValue={
                                state.targetDatabaseConfig?.projectId || ''
                              }
                              required
                            >
                              <option
                                value=""
                                disabled
                                style={{ display: 'none' }}
                              ></option>
                              {Object.entries(
                                state.targetDatabaseConfig?.projects || {}
                              ).map(([key, value]) => (
                                <option value={key} key={key}>
                                  {value}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <input
                              type="text"
                              name="projectId"
                              className="dwinputbox border-grey"
                              defaultValue={
                                state.targetDatabaseConfig?.projectId || ''
                              }
                              required
                            />
                          )}
                        </label>
                        <label className="grid-columns-2">
                          Dataset name
                          <input
                            type="text"
                            name="datasetName"
                            className="dwinputbox border-grey"
                            defaultValue={
                              state.targetDatabaseConfig?.datasetName || ''
                            }
                            required
                          />
                        </label>
                      </>
                    )}
                    <div className="dialog-response-area">
                      {updateDBFinished ? (
                        <div className="dialogtext">
                          {updateDBError ? (
                            <p className="error">
                              {isGoogleBQ && 'Connection to BigQuery failed - '}
                              {dbTestAndUpdateErrorMessage}
                            </p>
                          ) : (
                            <p className="success">
                              {isGoogleBQ
                                ? 'Connection to BigQuery succeeded'
                                : 'Succeeded'}
                            </p>
                          )}
                        </div>
                      ) : (
                        submittingDB && (
                          <div className="pos-center-dialog">
                            <Spinner />
                          </div>
                        )
                      )}
                    </div>
                  </>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    marginTop: '1em',
                    gap: isGoogleBQ && '0.5em',
                  }}
                >
                  <button
                    type="submit"
                    disabled={submittingDB}
                    className={`dwbutton dialogitem ${
                      isGoogleBQ ? 'btn btn-primary' : ''
                    }`}
                    style={{
                      backgroundColor: submittingDB
                        ? isGoogleBQ
                          ? 'var(--clr-bluefaded)'
                          : 'var(--clr-purplefaded)'
                        : false,
                    }}
                  >
                    {!isBYOD && submittingDB && (
                      <div className="pos-center">
                        <Spinner />
                      </div>
                    )}
                    {isGoogleBQ ? 'Save' : isBYOD ? 'UPDATE' : 'RENAME'}
                  </button>
                  <button
                    style={{
                      backgroundColor: submittingDB
                        ? isGoogleBQ
                          ? 'var(--clr-greyfaded)'
                          : 'var(--clr-purplefaded)'
                        : false,
                    }}
                    className={`dwbutton dialogitem ${
                      isGoogleBQ ? 'btn btn-secondary' : ''
                    }`}
                    onClick={() => {
                      if (isBYOD) {
                        setupdateDBMenu((state) => !state);
                        setupdateDBFinished(false);
                        setsubmittingDB(false);
                        setupdateDBError(false);
                        setDBTestAndUpdateErrorMessage('');
                      } else {
                        setupdateDBMenu((state) => !state);
                      }
                    }}
                  >
                    CANCEL
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      )}

      {changeSyncTime && (
        <div className="dialog success-time" ref={resetRef}>
          {successChangeSyncTime ? (
            <SuccessMessage
              onClose={() =>
                handleClose(setSuccessChangeSyncTime, setChangeSyncTime)
              }
              time={time}
            />
          ) : errorChangeSyncTime ? (
            <ErrorMessage
              onClose={() =>
                handleClose(setErrorChangeSyncTime, setChangeSyncTime)
              }
            />
          ) : (
            <SyncTimeForm
              onSubmit={updateSyncTime}
              optionTime={optionTime}
              isGoogleBQ={isGoogleBQ}
              isBYOD={isBYOD}
              submittingDB={submittingDB}
              changingSyncTime={changingSyncTime}
              onCancel={() => setChangeSyncTime((state) => !state)}
            />
          )}
        </div>
      )}

      {testDBConnection && (
        <div ref={resultRef} className="dialog connection-dialog">
          <div
            style={{
              fontSize: '14px',
              fontWeight: '700',
              gridColumn: 'span 2',
              textAlign: 'center',
              color: 'rgb(105, 114, 125)',
            }}
          >
            Connection Status
          </div>
          <div className="dialogtext text-center">
            <p>
              To update your connection close this window and select the Change{' '}
              {isBYOD ? 'Connection' : 'Database'} Info button.
            </p>
            {!isBYOD && (
              <p>If you leave a field blank no change will be made.</p>
            )}
          </div>
          {testDBFinished ? (
            <>
              {testDBError ? (
                <div className="dialogtext" style={{ marginBottom: '1em' }}>
                  <p className="error">
                    Connection {!isBYOD && 'to database'} failed -{' '}
                    {testDBErrorMessage}
                  </p>
                </div>
              ) : (
                <div className="dialogtext" style={{ marginBottom: '1em' }}>
                  <p className="success">
                    Connection {!isBYOD && 'to database'} succeeded
                  </p>
                </div>
              )}
            </>
          ) : (
            <div className="pos-center-dialog testdb-spinner-container">
              <Spinner />
            </div>
          )}
          <button
            style={{
              backgroundColor: submittingDB && 'var(--clr-purplefaded)',
            }}
            className="dwbutton dialogitem testdb-close-btn"
            onClick={() => {
              setTestDBConnection((state) => !state);
              setTestDBErrorMessage('');
              setTestDBError(false);
              setTestDBFinished(false);
            }}
          >
            Close
          </button>
        </div>
      )}
    </div>
    </>
  ) : (
    <div className="pos-center">
      <Spinner />
    </div>
  );
}

export default Detail;
